
import { apiService } from '../../../services/api.service'
import Vue from 'vue'

export default Vue.extend({
  props: {
    devices: { type: Array, required: true },
    editType: { type: String, required: true },
    openDialog: { type: Boolean, required: true },
    updateDevice: { type: Object, required: true }
  },

  data () {
    return {
      device: {
        clientName: '',
        label: ''
      }
    }
  },

  watch: {
    updateDevice: {
      handler (n) {
        this.device.clientName = n.client_name
        this.device.label = n.label
      }
    }
  },

  methods: {
    outsideClose () {
      this.$emit('close-dialog')
    },
    async editDeviceLabel (editType) {
      await apiService
        .put(
          `/admin-device-set-${editType}/${this.updateDevice.id}`,
          this.device
        )
        .then(() => {
          this.$emit('saved')
          this.$emit('close-dialog')
        })
    }
  }
})
