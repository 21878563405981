
import converter from '@/mixins/converter'
import devicesTables from '../components/devices/DeviceTable.vue'
export default {
  components: { devicesTables },
  mixins: [converter],
  data: () => ({
    dialog: false,
    dialogDelete: false,
    showUserDevices: false,
    userDevices: [-1] // to check is device table for 'all device'
  }),
  methods: {}
}
