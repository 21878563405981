<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-title color="rgba(0, 0, 0, 0)" flat>
          <span class="font-weight-regular" v-if="!!showUserDevices"
            >User Devices</span
          >
          <span class="font-weight-regular" v-else>Devices</span>
          <v-divider class="ml-2 mr-2" vertical></v-divider>
          <v-btn @click="initialize" icon>
            <v-icon dark>mdi-sync</v-icon>
          </v-btn>
          <v-chip v-if="!!showUserDevices" small color="indigo">
            <span class="white--text">{{ userName }}</span>
          </v-chip>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            class="mr-2"
            clearable
            dense
            hide-details
            label="Search devices"
            outlined
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn disabled outlined color="primary">
            <v-icon left> mdi-plus </v-icon>
            new devices
          </v-btn>
        </v-card-title>

        <edit-device
          :updateDevice="oneDevice"
          :devices="devices"
          :openDialog="openDialog"
          :editType="editType"
          @close-dialog="openDialog = false"
          @saved="initialize"
        ></edit-device>

        <v-card-text class="mb-0 pb-0 mx-0 px-0">
          <v-data-table
            :loading="deviceLoading"
            :search="search"
            dense
            :headers="headers"
            :items="devices"
            mobile-breakpoint="0"
            ref="devicesTable"
            sort-desc
            :items-per-page="30"
            :footer-props="{
              'items-per-page-options': [10, 30, 50, -1],
            }"
          >
            <template v-slot:[`item.client_name`]="{ item }">
              <span v-if="item.client_name">{{ item.client_name }} </span>
              <v-icon v-else>mdi-minus</v-icon>
              <v-btn
                @click="editDeviceName(item, 'name')"
                x-small
                icon
                color="indigo"
                class="ml-2 pa-0"
                ><v-icon>mdi-circle-edit-outline</v-icon>
              </v-btn>
            </template>

            <template v-slot:[`item.label`]="{ item }">
              <span v-if="item.label">{{ item.label }}</span>
              <v-icon v-else>mdi-minus</v-icon>
              <v-btn
                @click="editDeviceName(item, 'label')"
                x-small
                icon
                color="indigo"
                class="ml-2 pa-0"
                ><v-icon>mdi-circle-edit-outline</v-icon>
              </v-btn>
            </template>

            <template v-slot:[`item.issued_at`]="{ item: { issued_at } }">
              <span>{{ timeParser(issued_at) }}</span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                v-if="!item.deleted"
                @click="deleteDevice(item.id)"
                color="red"
                icon
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>

              <v-tooltip v-else bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    @click="restoreDevice(item.id)"
                    color="orange"
                    icon
                  >
                    <v-icon>mdi-restore</v-icon>
                  </v-btn>
                </template>
                <span>Restore device deleted status as "False"</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.cpu`]="{ item }">
              <span v-if="cpuExists(item.device_params)"
                >{{ cpuExists(item.device_params) }}
              </span>
              <v-icon v-else>mdi-minus</v-icon>
            </template>

            <template v-slot:[`item.gpu`]="{ item }">
              <span v-if="gpuExists(item.device_params)"
                ><span>{{ gpuExists(item.device_params) }}</span
                >x
                <v-icon>mdi-expansion-card-variant</v-icon>
              </span>
              <v-icon v-else>mdi-minus</v-icon>
            </template>

            <template v-slot:[`item.memory`]="{ item }">
              <span v-if="memoryExist(item.device_params)"
                >{{ parseFloat(memoryExist(item.device_params)).toFixed(2) }} GB
              </span>
              <v-icon v-else>mdi-minus</v-icon>
            </template>

            <template v-slot:[`item.client`]="{ item }">
              <span v-if="clientExist(item)">{{ clientExist(item) }} </span>
              <v-icon v-else>mdi-minus</v-icon>
            </template>

            <template v-slot:[`item.deleted`]="{ item }">
              <v-icon :color="item.deleted ? 'red' : 'grey'">
                {{ item.deleted ? 'mdi-check' : 'mdi-minus' }}</v-icon
              >
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import converter from '@/mixins/converter'
import get from 'lodash/get'
import Swal from 'sweetalert2'
import { apiService } from '../../../services/api.service'
import EditDevice from './DeviceEdit.vue'
export default converter.extend({
  components: { EditDevice },
  props: {
    userDevices: { type: Array, required: true },
    showUserDevices: { type: Boolean, required: true },
    userName: { type: String }
  },
  data () {
    return {
      deviceLoading: false,
      editType: '',
      openDialog: false,
      oneDevice: {},
      devices: [],
      search: null,
      headers: [
        { text: 'Owner Name', value: 'user.name' },
        { text: 'Owner Email', value: 'user.email' },
        { text: 'Name', value: 'client_name' },
        { text: 'Label', value: 'label' },
        { text: 'Created', value: 'issued_at' },
        { text: 'CPU', value: 'cpu', align: 'center' },
        { text: 'GPU', value: 'gpu', align: 'center', sortable: false },
        { text: 'Memory', value: 'memory', align: 'center' },
        { text: 'Client', value: 'client', align: 'center' },
        { text: 'Deleted', value: 'deleted', align: 'center' },
        { text: 'Actions', value: 'actions', sortable: false, align: 'center' }
      ]
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    async initialize () {
      this.deviceLoading = true
      if (this.showUserDevices) {
        this.devices = this.userDevices
        this.deviceLoading = false
      }
      if (!this.showUserDevices) {
        await apiService
          .get('admin-get-devices')
          .then((res) => {
            this.devices = res.data.data
          })
          .finally(() => (this.deviceLoading = false))
      }
    },

    async editDeviceName (item, type) {
      this.openDialog = true
      this.editType = type
      this.oneDevice = item
    },

    async deleteDevice (deviceId) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'The device deleted status will be True.',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            await apiService
              .put(`admin-device-delete-status/${deviceId}`)
              .catch((err) => {
                Swal.showValidationMessage(`Request failed: ${err}`)
              })
            this.initialize()
            Swal.fire('Deleted!', 'Your file has been deleted.', 'success')
          }
        })
        .finally(() => (this.deviceLoading = false))
    },

    async restoreDevice (deviceId) {
      await apiService.put(`admin-device-restore/${deviceId}`).catch((err) => {
        Swal.showValidationMessage(`Request failed: ${err}`)
      })
      this.initialize()
    },

    getParse (item) {
      return JSON.parse(item)
    },

    deviceParser (deviceParams) {
      const parsed = this.getParse(deviceParams)
      return parsed
    },

    gpuExists (deviceParams) {
      const parsed = this.deviceParser(deviceParams)
      return get(parsed, 'gpu', []).length
    },
    cpuExists (deviceParams) {
      const parsed = this.deviceParser(deviceParams)
      return get(parsed, 'cpu', {}).brand
    },
    memoryExist (deviceParams) {
      const parsed = this.deviceParser(deviceParams)
      return get(parsed, 'memory', {}).total
    },
    clientExist (item) {
      return get(item, 'cordatus_version', {})
    },

    checkCondition (device, condition) {
      if (!condition) return true
      let finalDecision = true
      for (let index = 0; index < Object.keys(condition).length; index++) {
        const cond = Object.keys(condition)[index]
        if (device[cond] !== condition[cond]) {
          finalDecision = false
          break
        }
      }
      return finalDecision
    }
  }
})
</script>

<style>
</style>
